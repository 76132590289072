import React, { useRef, useState, useEffect } from 'react';

import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';

import { IconButton, Typography } from '@material-ui/core';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';

import MyLocation from './MyLocation';
import { isOnline } from './dataUrl';

import myBestStyle from './style/style.json';

function Map({
  viewportWidth,
  viewportHeight,
  centerLongitude,
  centerLatitude,
  shareMap,
  trackBalloon,
}) {
  const [zoom, setZoom] = useState(6);
  const [map, setMap] = useState(null);
  const mapContainerRef = useRef(null);
  const [showMyLocation, setShowMyLocation] = useState(false);
  const [locateMeCounter, setLocateMeCounter] = useState(0);

  const zoomIn = () => {
    if (map) {
      map.setZoom(zoom + 1);
      setZoom(zoom + 1);
    }
  };

  const zoomOut = () => {
    if (map) {
      map.setZoom(zoom - 1);
      setZoom(zoom - 1);
    }
  };

  const locateMe = () => {
    if (!showMyLocation) {
      setShowMyLocation(true);
    }
    setLocateMeCounter((c) => c + 1);
  };

  useEffect(() => {
    if (!isOnline()) {
      // frontend is used locally served from a device
      // `window.location.hostname` is the device ip address
      myBestStyle.sources.openmaptiles.url = `http://${window.location.hostname}:10001/data/v3.json`;
    }
    const mapInner = new maplibregl.Map({
      container: mapContainerRef.current,
      style: myBestStyle,
      center: [centerLongitude, centerLatitude],
      zoom,
      attributionControl: false,
    });

    const scale = new maplibregl.ScaleControl({
      maxWidth: 80,
      unit: 'metric',
    });
    mapInner.addControl(scale);

    mapInner.dragRotate.disable();
    mapInner.touchZoomRotate.disableRotation();

    mapInner.on('move', () => {
      setZoom(mapInner.getZoom());
    });

    mapInner.on('load', () => {
      mapInner.resize();
    });

    setMap(mapInner);
    shareMap(mapInner);
  }, []);

  useEffect(() => {
    if (map) {
      map.resize();
    }
  }, [viewportWidth, viewportHeight]);

  return (
    <div>
      <div
        ref={mapContainerRef}
        style={{
          position: 'absolute',
          width: (viewportWidth - 1),
          height: (viewportHeight - 1),
          overflow: 'hidden',
        }}
      />

      <div style={{
        position: 'absolute',
        right: 0,
        top: 0,
        padding: 10,
      }}
      >
        <Box display="flex" flexDirection="column">
          <Tooltip title="Zoom In" placement="left">
            <IconButton onClick={zoomIn}>
              <AddIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Zoom Out" placement="left">
            <IconButton onClick={zoomOut}>
              <RemoveIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Track Balloon" placement="left">
            <IconButton onClick={trackBalloon}>
              <ArrowForwardIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Locate Me" placement="left">
            <IconButton onClick={locateMe}>
              <MyLocationIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </div>

      <div style={{
        position: 'absolute',
        right: 0,
        bottom: 0,
      }}
      >
        <Box display="flex" justifyContent="flex-end" mx={1} color="text.secondary">
          <Typography style={{ fontSize: 12 }}>
            <Link color="inherit" href="https://www.openaip.net/">© openAIP </Link>
            <Link color="inherit" href="https://www.openstreetmap.org/about/">© OpenStreetMap</Link>
          </Typography>
        </Box>
      </div>

      {showMyLocation && (
      <MyLocation
        map={map}
        locateMeCounter={locateMeCounter}
      />
      )}
    </div>
  );
}

export default Map;

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import React, { useEffect } from 'react';

import axios from 'axios';

import App from './App';
import Home from './Home';
import LiveMap from './LiveMap';

export default function Main() {
  useEffect(() => {
    if (!window.location.hostname.includes('ballometer.io') && window.location.pathname === '/') {
      // frontend is used locally served from a device
      // `window.location.hostname` is the device ip address
      // user tries to access root endpoint
      // redirect to /<username> endpoint
      axios.get(`http://${window.location.hostname}/store/username`)
        .then((res) => {
          const username = res.data;
          window.location.href = `http://${window.location.hostname}/${username}`;
        })
        .catch(() => {});
    }
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/map" element={<LiveMap />} />
        <Route path="/:username/:flightId" element={<App />} />
        <Route path="/:username" element={<App />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

const isOnline = () => process.env.NODE_ENV === 'development' || window.location.hostname.includes('ballometer.io') || window.location.hostname.includes('ballometer-experimental.online');

export { isOnline };

const dataUrl = () => {
  if (isOnline()) {
    // frontend is used via the internet
    return window.location.hostname.includes('ballometer-experimental.online') ? 'https://api.ballometer-experimental.online/read/' : 'https://api.ballometer.io/read/';
  }
  // frontend is used locally served from a device
  // `window.location.hostname` is the device ip address
  return `http://${window.location.hostname}/store/`;
};

export default dataUrl;

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import './Side.css';
import isBalz from './isBalz';

function Side({
  title,
  viewportWidth,
  viewportHeight,
  handleClose,
  children,
}) {
  const sideWidth = isBalz() ? 1050 : 350;
  return (
    <div>
      <div
        style={{
          position: 'absolute',
          width: (viewportWidth > sideWidth + 5 ? sideWidth : (viewportWidth - 5)),
          height: viewportHeight,
          overflowY: 'auto',
          boxShadow: '5px 0 10px #AAAAAA',
          paddingRight: 5,
          backgroundColor: 'white',
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Box p={1}>
            <Typography variant="h4" gutterBottom>
              {title}
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>

        </Box>
        {children}
      </div>
    </div>
  );
}

export default Side;

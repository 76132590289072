import React, { useState, useRef, useEffect } from 'react';

import maplibregl from 'maplibre-gl';

function HtmlMarker({
  map,
  longitude,
  latitude,
  children,
}) {
  const ref = useRef(null);
  const [marker, setMarker] = useState(null);

  useEffect(() => {
    if (map) {
      const markerInner = new maplibregl.Marker({
        element: ref.current,
      })
        .setLngLat([longitude, latitude])
        .addTo(map);
      setMarker(markerInner);
    }
  }, [map]);

  useEffect(() => {
    if (marker) {
      marker.setLngLat([longitude, latitude]);
    }
  }, [longitude, latitude]);

  return (
    <div ref={ref}>
      { children }
    </div>
  );
}

export default HtmlMarker;

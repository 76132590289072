import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import './Home.css';

function Home() {
  return (
    <Container style={{ maxWidth: '8in', padding: 0 }}>
      <Paper>
        <Body />
      </Paper>
    </Container>
  );
}

function Paragraph({ children }) {
  return (
    <Box px={2} py={1}><Typography>{children}</Typography></Box>
  );
}

function Subtitle({ children }) {
  return (
    <Box px={2} pb={1}><Typography variant="h4">{children}</Typography></Box>
  );
}

function Body() {
  return (
    <div>
      <img src="/home/titlebild.jpg" alt="Balloon Over Snow" width="100%" />
      <Paragraph>
        The Ballometer is a sensor box for hot air balloons.
        It allows pilots to measure and log flight data such as GPS position and velocity, barometric altitude and vertical speed.
        The measurements are stored on the device and if you connect it to the internet via a mobile phone wifi hot spot, it will send the measurements to
        {' '}
        <a href="https://www.ballometer.io">www.ballometer.io</a>
        .
        Like this your friends can track you in real time.
        The flight measurements are archived online and ordered by date.
        This allows you to share memories of old flights with others in the future.
      </Paragraph>

      <Subtitle>Price</Subtitle>
      <Paragraph>
        The Ballometer BM1 costs CHF 490, shipping excluded. The subscription for sharing the measurements online on ballometer.io costs CHF 60 per year.
      </Paragraph>

      <Subtitle>
        Order by Email
      </Subtitle>

      <Paragraph>
        <a href="mailto:oliver.wipfli@leichteralsluft.ch">oliver.wipfli@leichteralsluft.ch</a>
        <br />
        <br />
        Leichter als Luft GmbH
        <br />
        Gässli 37
        <br />
        5062 Oberhof
        <br />
        Switzerland
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Paragraph>
    </div>
  );
}

export default Home;

import React from 'react';

import moment from 'moment';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import isBalz from './isBalz';

function Chips({
  speed, heading, altitude, climb, time, utcOffset, distanceFromStart, distanceFromStartIn2h,
}) {
  return (
    <div style={{
      position: 'absolute',
      right: 10,
      bottom: 10,
      pointerEvents: 'none',
    }}
    >
      <Box
        flexDirection="row"
        display="flex"
        flexWrap="wrap-reverse"
        justifyContent="flex-end"
        alignContent="center"
      >
        <Box p={1}>
          <Paper>
            <Box textAlign="center" mx={1} pt={0.5} style={{ minWidth: '9ch' }}>
              {((3.6 * speed) / (isBalz() ? 1.852 : 1.0)).toFixed(1)}
              {isBalz() ? ' kn' : ' km/h'}
            </Box>
            <Box textAlign="center" mx={1}>
              <Typography variant="overline">SPEED</Typography>
            </Box>
          </Paper>
        </Box>
        <Box p={1}>
          <Paper>
            <Box textAlign="center" mx={1} pt={0.5}>
              {heading.toFixed(0)}
              °
            </Box>
            <Box textAlign="center" mx={1}>
              <Typography variant="overline">HEADING</Typography>
            </Box>
          </Paper>
        </Box>
        <Box p={1}>
          <Paper>
            <Box textAlign="center" mx={1} pt={0.5}>
              {(altitude / (isBalz() ? 0.3048 : 1.0)).toFixed(0)}
              {isBalz() ? ' ft' : ' m'}
            </Box>
            <Box textAlign="center" mx={1}>
              <Typography variant="overline">ALTITUDE</Typography>
            </Box>
          </Paper>
        </Box>
        <Box p={1}>
          <Paper>
            <Box textAlign="center" mx={1} pt={0.5} style={{ minWidth: '7ch' }}>
              {climb.toFixed(1)}
              {' '}
              m/s
            </Box>
            <Box textAlign="center" mx={1}>
              <Typography variant="overline">CLIMB</Typography>
            </Box>
          </Paper>
        </Box>
        <Box p={1}>
          <Paper>
            <Box textAlign="center" mx={1} pt={0.5} style={{ minWidth: '7ch' }}>
              {moment(time * 1000).utcOffset(isBalz() ? 0 : utcOffset).format('HH:mm')}
            </Box>
            <Box textAlign="center" mx={1}>
              <Typography variant="overline">{isBalz() ? 'UTC' : 'TIME '}</Typography>
            </Box>
          </Paper>
        </Box>
        <Box p={1}>
          <Paper>
            <Box textAlign="center" mx={1} pt={0.5} style={{ minWidth: '7ch' }}>
              {(distanceFromStart / (isBalz() ? 1.852 : 1.0)).toFixed(1)}
              {isBalz() ? ' NM' : ' km'}
            </Box>
            <Box textAlign="center" mx={1}>
              <Typography variant="overline">DISTANCE</Typography>
            </Box>
          </Paper>
        </Box>
        {isBalz() && (
          <Box p={1}>
            <Paper>
              <Box textAlign="center" mx={1} pt={0.5} style={{ minWidth: '7ch' }}>
                {(distanceFromStartIn2h / 1.852).toFixed(1)}
                {' '}
                NM
              </Box>
              <Box textAlign="center" mx={1}>
                <Typography variant="overline">IN 2H</Typography>
              </Box>
            </Paper>
          </Box>
        )}
      </Box>
    </div>
  );
}

export default Chips;
